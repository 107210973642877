import { nextTick } from "vue"
export default {
  state: {
    keepAliveInclude: [], // 缓存组件的include
    mcBodyFullScreen: false, // 内容全屏
    tableBorder: false, // 表格边框
    tableSize: 'default', // 表格尺寸 large、default、small
    tableHeight: 600, // 表格高度
  },

  mutations: {

    // 设置keepAliveIclude
    SET_KEEP_ALIVE_INCLUDE(state, data){
      data.forEach(item => {
        if (!state.keepAliveInclude.includes(item.name)) {
          state.keepAliveInclude.push(item.name)
        }
      })
      for(const i in state.keepAliveInclude) {
        const filter = data.filter(item => { return item.name == state.keepAliveInclude[i] })
        if (filter.length === 0) {
          state.keepAliveInclude = state.keepAliveInclude.splice(i-1, 1)
        }
      }
    },

    // 设置内容全屏
    SET_MCBODY_FULL_SCREEN(state, data){
      state.mcBodyFullScreen = data
    },

    // 设置表格边框
    SET_TABLE_BORDER(state, data){
      state.tableBorder = data
    },

    // 设置表格尺寸
    SET_TABLE_SIZE(state, data){
      state.tableSize = data
    },

    // 设置表格高度
    SET_TABLE_HEIGHT(state, data){
      state.tableHeight = data
    },

  },

  actions: {

    // 设置keepAliveIclude
    setKeepAliveInclude(context, data){
      context.commit('SET_KEEP_ALIVE_INCLUDE', data)
    },

    // 设置内容全屏
    setmcBodyFullScreen(context, data){
      context.commit('SET_MCBODY_FULL_SCREEN', data)
    },

    // 设置表格边框
    setTableBorder(context, data){
      context.commit('SET_TABLE_BORDER', data)
    },

    // 设置表格尺寸
    setTableSize(context, data){
      context.commit('SET_TABLE_SIZE', data)
    },

    // 设置表格高度
    setTableHeight(context){
      nextTick(() => {
        const table = document.querySelector('.mcBody .el-table')
        if (table) {
          const pagination = document.querySelector('.mcBody .el-pagination')
          const marginBottom = pagination ? 72 : 20
          const tableHeight = window.innerHeight - table.offsetTop - marginBottom
          context.commit('SET_TABLE_HEIGHT', tableHeight)
          window.onresize = null
          window.onresize = function(){
            const tableHeight = window.innerHeight - table.offsetTop - marginBottom
            context.commit('SET_TABLE_HEIGHT', tableHeight)
          }
        }
      })
    }

  }
}