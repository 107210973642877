import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router/index.js';
import store from '@/store/index.js';
import ElementPlus from 'element-plus';
import zhCN from 'element-plus/lib/locale/lang/zh-cn';  // element语言包（简体中文）
import en from 'element-plus/lib/locale/lang/en';       // element语言包（英语）
import th from 'element-plus/lib/locale/lang/th';       // element语言包（泰国语）
import vi from 'element-plus/lib/locale/lang/vi';       // element语言包（越南语）
import i18n from '@/utils/i18n';
import VabIcon from 'vab-icons';
import 'vab-icons/lib/vab-icons.css';
import 'element-plus/dist/index.css';
import '@/assets/css/global.css';

// 配置element语言包
const app = createApp(App);
const roleId = localStorage.getItem('roleId');
const permissions = JSON.parse(localStorage.getItem('permissions')) || []; // 按钮权限
const language = localStorage.getItem('language') || 'zh-CN';
const messages = {
  'zh-CN': zhCN,
  'en': en,
  'th': th,
  'vi': vi,
};

// 添加权限指令
app.directive("auth", {
  mounted(el, param){
    if (roleId != 1 && !permissions.includes(param.value)) {
      el.remove?.()
    }
  }
});

// 配置app
app.use(router)
.use(store)
.use(i18n)
.use(ElementPlus, { locale: messages[language] })
.component('VabIcon', VabIcon)
.mount('#app');

// 防抖
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}