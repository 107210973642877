import qs from 'qs'
import axios from 'axios'
import store from '@/store/index'
import { ElMessage } from 'element-plus'
import { translate } from '@/utils/i18n'

window.BASE_URL = process.env.VUE_APP_FLAG
const service = axios.create({
  baseURL: window.BASE_URL, // api 的 base_url
  timeout: 15000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    const accessToken = JSON.parse(localStorage.getItem('accessToken')) || ''
    config.headers['Lang'] = localStorage.getItem('language')
    if (accessToken) {
      config.headers['Mate-Auth'] = `Bearer ${accessToken.value}`
      // config.headers['Content-Type'] = 'application/json;charset=UTF-8' // axios自动设置，手动设置后会导致上传图片失败
      config.headers['Version'] = 'v1'
      config.headers['Client-Type'] = 'web'
    }
    if (config.data && config.headers['Content-Type'] == 'application/x-www-form-urlencoded;charset=UTF-8') {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response拦截器
service.interceptors.response.use(
  response => {
    if (response.status === 200 && response.config.getAllData) { // 返回全部数据
      if (response.data.code && response.data.code != 200) {
        ElMessage.error(response.data.msg || translate('网络请求失败，请稍候再试'))
        return Promise.reject(response.data)
      } else {
        return response
      }
    } else if (response.data.code != 200) {
      ElMessage.error(response.data.msg || translate('网络请求失败，请稍候再试'))
      return Promise.reject(response.data)
    } else {
      return response.data
    }
  },
  error => {
    if (error.response.status == 401) {
      store.dispatch('logOut')
      localStorage.setItem('LoginStatusHasExpired', '登录状态已过期')
      location.href = `${location.origin}`
      return Promise.reject(error)
    }
    ElMessage.error(error.response.data.msg || translate('网络请求失败，请稍候再试'))
    return Promise.reject(error)
  }
)

export default service
