/* i18n语言包（泰国语） */

export default {
  'sys.operator.success': 'ความสำเร็จในการดำเนินงาน',
  '业务异常': 'ความผิดปกติทางธุรกิจ',
  '网络请求失败，请稍候再试': 'คำขอเครือข่ายล้มเหลวโปรดรอสักครู่เพื่อลองอีกครั้ง',
  '您好！': 'สวัสดี!',
  '欢迎来到license！': 'ยินดีต้อนรับสู่ license!',
  '请输入公司关键词': 'กรุณาใส่คำหลักของบริษัท',
  '公司名不能为空': 'ชื่อบริษัทต้องไม่ว่างเปล่า',
  '请输入用户名': 'กรุณาใส่ชื่อผู้ใช้',
  '用户名不能为空': 'ชื่อผู้ใช้ไม่สามารถว่างเปล่า',
  '请输入密码': 'กรุณาใส่รหัสผ่าน',
  '密码不能为空': 'รหัสผ่านต้องไม่ว่างเปล่า',
  '登录': 'ล็อกอิน',
  '退出登录': 'ออกจากระบบ',
  '登录成功': 'ล็อกอินสำเร็จ',
  '暂无权限，请联系后台管理员': 'ไม่มีสิทธิ์ชั่วคราวโปรดติดต่อผู้ดูแลระบบหลังบ้าน',
  '向右滑动完成拼图': 'ปัดขวาปริศนาเสร็จ',
  '刷新': 'รีเฟรช',
  '抱歉！': 'ขอโทษนะ!',
  '当前页面不存在...': 'ไม่มีหน้าปัจจุบัน...',
  '请检查您输入的网址是否正确，或点击下面的按钮返回首页。': 'โปรดตรวจสอบว่า URL ที่คุณป้อนถูกต้องหรือคลิกที่ปุ่มด้านล่างเพื่อกลับไปที่หน้าแรก',
  '登录状态已过期，请重新登录!': 'สถานะการเข้าสู่ระบบหมดอายุแล้ว กรุณาเข้าสู่ระบบใหม่!',
  '返回首页': 'กลับไปที่บ้าน',
  '关闭其他': 'ปิดอื่น ๆ', 
  '关闭左侧': 'ปิดซ้าย', 
  '关闭右侧': 'ปิดขวา', 
  '关闭全部': 'ปิดทั้งหมด',
  '搜索': 'ค้นหา',
  '清空': 'ล้าง',
  '添加': 'เพิ่ม',
  '删除': 'ลบ',
  '批量删除': 'ลบแบทช์',
  '启用': 'เปิดใช้',
  '禁用': 'ปิดการใช้งาน',
  '编辑': 'แก้ไข',
  '修改': 'แก้ไข',
  '审核': 'รีวิว',
  '反审核': 'การตรวจสอบย้อนกลับ',
  '条码': 'บาร์โค้ด',
  '详情': 'รายละเอียด',
  '导出Excel': 'ส่งออก Excel',
  '边框': 'ชายแดน',
  '大': 'ใหญ่',
  '中': 'กลาง',
  '小': 'ขนาดเล็ก',
  '许可证书管理': 'การจัดการใบอนุญาต', 
  '许可证书': 'ใบอนุญาต',
  '请输入许可证名称': 'กรุณาใส่ชื่อใบอนุญาต',
  '系统管理': 'การจัดการระบบ', 
  '菜单管理': 'การจัดการเมนู',
  '开始日期': 'วันเริ่มต้น',
  '至': 'ถึง',
  '结束日期': 'วันที่สิ้นสุด',
  'ID或菜单名称': 'ID หรือชื่อเมนู',
  '请输入权限值': 'โปรดป้อนค่าสิทธิ์',
  '菜单名称': 'ชื่อเมนู',
  '图标': 'ไอคอน',
  '选择图标': 'เลือกไอคอน',
  '组件路径': 'เส้นทางส่วนประกอบ',
  '类型': 'ประเภท',
  '排序': 'จัดเรียง',
  '目录': 'แคตตาล็อก',
  '菜单': 'เมนู',
  '按钮': 'ปุ่ม',
  '未选中任何行': 'ไม่เลือกแถว',
  '操作成功': 'ความสำเร็จในการดำเนินงาน',
  '确认要启用选中的': 'ยืนยันการเปิดใช้การเลือก',
  '确认要禁用选中的': 'ยืนยันที่จะปิดการใช้งานการเลือก',
  '确认要删除选中的': 'ยืนยันที่จะลบข้อมูล',
  '条数据？': 'ที่เลือก?',
  '取消': 'ยกเลิก',
  '确定': 'ตกลง',
  '添加菜单': 'เพิ่มเมนู',
  '编辑菜单': 'แก้ไขเมนู',
  '上级菜单': 'เมนูพรีเมี่ยม',
  '请选择上级菜单': 'กรุณาเลือกเมนูด้านบน',
  '菜单类型': 'ประเภทเมนู',
  '菜单图标': 'ไอคอนเมนู',
  '图标选择器': 'ไอคอนเลือก',
  '显示排序': 'แสดงการเรียงลำดับ',
  '路由地址': 'ที่อยู่เส้นทาง',
  '组件地址': 'ที่อยู่ส่วนประกอบ',
  '路由重定向': 'การเปลี่ยนเส้นทาง',
  '权限标识': 'การระบุสิทธิ์',
  '菜单状态': 'สถานะเมนู',
  '是否隐藏': 'ซ่อนหรือไม่',
  '请输入菜单名称': 'กรุณาใส่ชื่อเมนู',
  '请输入排序': 'โปรดป้อนการเรียงลำดับ',
  '请输入路由地址': 'โปรดป้อนที่อยู่เส้นทาง',
  '请输入组件地址': 'โปรดป้อนที่อยู่ส่วนประกอบ',
  '请输入路由重定向': 'กรุณากรอกเส้นทาง',
  '请输入权限标识': 'กรุณาระบุสิทธิ์',
  '显示': 'แสดง',
  '隐藏': 'ซ่อน',
  '添加子项': 'เพิ่มรายการย่อย',
  '添加翻译': 'เพิ่มการแปล',
  '确认删除名称为': 'แน่ใจว่าคุณต้องการลบรายการข้อมูลที่เรียกว่า',
  '的数据项?': '?',
  '语言类型': 'ประเภทภาษา',
  '翻译内容': 'แปลเนื้อหา',
  '请先选中某一数据行进行操作': 'โปรดตรวจสอบบรรทัดข้อมูลบางอย่างสำหรับการดำเนินการ',
  '请选择语言类型': 'กรุณาเลือกประเภทภาษา',
  '请输入翻译内容': 'โปรดป้อนคำแปล',
  '确认要删除当前项吗？': 'ยืนยันที่จะลบรายการปัจจุบัน?',
  '确认要删除选中项吗？': 'ยืนยันที่จะลบการเลือก?',
  '操作': 'การดำเนินงาน',
  '角色管理': 'การจัดการบทบาท',
  '角色编号': 'หมายเลขบทบาท',
  '角色名称': 'ชื่อตัวละคร',
  '角色编码': 'รหัสบทบาท',
  '角色描述': 'คำอธิบายบทบาท',
  '请输入角色编码': 'โปรดป้อนรหัสบทบาท',
  '请输入角色名称': 'กรุณาใส่ชื่อตัวละคร',
  '请输入描述': 'กรุณาใส่คำอธิบาย',
  '描述': 'คำอธิบาย',
  '添加角色': 'เพิ่มตัวละคร',
  '编辑角色': 'แก้ไขบทบาท',
  '权限设置': 'การตั้งค่าสิทธิ์',
  '保存权限设置': 'บันทึกการตั้งค่าสิทธิ์',
  '用户管理': 'การจัดการผู้ใช้',
  '请选择角色': 'กรุณาเลือกตัวละคร',
  '请选择部门': 'กรุณาเลือกแผนก',
  '用户编号': 'หมายเลขผู้ใช้',
  '用户头像': 'อวตารของผู้ใช้',
  '账号': 'หมายเลขบัญชี',
  '昵称': 'ชื่อเล่น',
  '角色': 'บทบาท',
  '部门': 'แผนก',
  '手机号码': 'หมายเลขโทรศัพท์มือถือ',
  '邮箱': 'กล่องจดหมาย',
  '状态': 'สถานะ',
  '账户': 'บัญชี',
  '姓名': 'ชื่อ',
  '密码': 'รหัสผ่าน',
  '确认密码': 'ยืนยันรหัสผ่าน',
  '性别': 'เพศ',
  '男': 'ชาย',
  '女': 'ผู้หญิง',
  '职务': 'ตำแหน่ง',
  '员工编号': 'หมายเลขพนักงาน',
  '出生日期': 'วันเดือนปีเกิด',
  '用户状态': 'สถานะผู้ใช้',
  '请输入账户': 'โปรดป้อนบัญชี',
  '请输入姓名': 'กรุณาใส่ชื่อ',
  '请输入确认密码': 'กรุณาใส่รหัสผ่านยืนยัน',
  '请输入员工编号': 'กรุณาใส่หมายเลขพนักงาน',
  '添加用户': 'เพิ่มผู้ใช้',
  '编辑用户': 'แก้ไขผู้ใช้',
  '请输入手机号码': 'กรุณากรอกหมายเลขโทรศัพท์มือถือ',
  '请输入邮箱地址': 'โปรดป้อนที่อยู่อีเมล',
  '请选择职务': 'กรุณาเลือกตำแหน่งงาน',
  '请选择出生日期': 'กรุณาเลือกวันเดือนปีเกิด',
  '两次输入的密码不一致': 'รหัสผ่านไม่สอดคล้องกันสองครั้ง',
  '长度在 6 到 20 个字符': 'ความยาวตั้งแต่ 6 ถึง 20 ตัวอักษร',
  '重置密码': 'รีเซ็ตรหัสผ่าน',
  '部门管理': 'การจัดการแผนก',
  '添加部门': 'เพิ่มแผนก',
  '编辑部门': 'กองบรรณาธิการ',
  '部门名称': 'ชื่อแผนก',
  '部门编号': 'หมายเลขแผนก',
  '部门负责人': 'หัวหน้าแผนก',
  '上级部门': 'หน่วยงานระดับสูง',
  '负责人': 'ผู้รับผิดชอบ',
  '请选择上级部门': 'กรุณาเลือกหน่วยงานระดับสูง',
  '请输入部门名称': 'กรุณาใส่ชื่อแผนก',
  '职务管理': 'การจัดการงาน',
  '部门职务管理': 'การบริหารงานของแผนก',
  '请选择部门负责人': 'กรุณาเลือกหัวหน้าแผนก',
  '选择负责人': 'เลือกผู้รับผิดชอบ',
  '返回': 'กลับ',
  '职务详情列表': 'รายการรายละเอียดงาน',
  '职务名称': 'ชื่องาน',
  '创建人': 'สร้างโดย',
  '创建人ID': 'รหัสผู้สร้าง',
  '创建时间': 'สร้างเวลา',
  '更新人': 'อัพเดตโดย',
  '更新人ID': 'อัปเดต ID',
  '更新时间': 'อัปเดตเวลา',
  '请输入职务名称': 'โปรดป้อนชื่องาน',
  '添加职务': 'เพิ่มตำแหน่ง',
  '编辑职务': 'ตำแหน่งบรรณาธิการ',
  '字典管理': 'การจัดการพจนานุกรม',
  '字典编号或名称': 'หมายเลขพจนานุกรมหรือชื่อ',
  '添加字典': 'เพิ่มพจนานุกรม',
  '编辑字典': 'แก้ไขพจนานุกรม',
  '字典编号': 'หมายเลขพจนานุกรม',
  '字典名称': 'ชื่อพจนานุกรม',
  '选项名称': 'ชื่อตัวเลือก',
  '选项值': 'ค่าตัวเลือก',
  '请输入字典编号': 'กรุณาใส่หมายเลขพจนานุกรม',
  '请输入字典名称': 'โปรดป้อนชื่อพจนานุกรม',
  '请输入选项值': 'โปรดป้อนค่าตัวเลือก',
  '请输入选项名称': 'โปรดป้อนชื่อตัวเลือก',
  '备注': 'หมายเหตุ',
  '请输入备注': 'กรุณาใส่หมายเหตุ',
  '添加字典选项': 'เพิ่มตัวเลือกพจนานุกรม',
  '编辑字典选项': 'แก้ไขตัวเลือกพจนานุกรม',
  '字典列表': 'รายการพจนานุกรม',
  '字典选项': 'ตัวเลือกพจนานุกรม',
  '翻译列表': 'รายการแปล',
  '租户管理': 'การจัดการผู้เช่า',
  '添加租户': 'เพิ่มผู้เช่า',
  '配置': 'การกำหนดค่า',
  '序号': 'ลำดับ',
  '租户logo': 'โลโก้ผู้เช่า',
  '租户名称': 'ชื่อผู้เช่า',
  '租户ID': 'รหัสผู้เช่า',
  '联系人': 'ติดต่อ',
  '联系电话': 'เบอร์ติดต่อ',
  '开始时间': 'เวลาเริ่มต้น',
  '结束时间': 'เวลาสิ้นสุด',
  '请输入租户名称': 'กรุณากรอกชื่อผู้เช่า',
  '请输入租户编码': 'กรุณาใส่รหัสผู้เช่า',
  '请输入联系人': 'โปรดป้อนรายชื่อ',
  '请输入联系电话': 'กรุณากรอกเบอร์ติดต่อ',
  '请选择开始时间': 'โปรดเลือกเวลาเริ่มต้น',
  '请选择结束时间': 'โปรดเลือกเวลาสิ้นสุด',
  '租户编码': 'รหัสผู้เช่า',
  '备注：租户默认管理用户：admin，密码为联系电话。': 'หมายเหตุ: ผู้เช่าจัดการค่าเริ่มต้นผู้ใช้: admin, รหัสผ่านเป็นหมายเลขติดต่อ',
  '公司logo': 'โลโก้ บริษัท',
  '系统名称': 'ชื่อระบบ',
  '域名': 'ชื่อโดเมน',
  '复杂配置': 'การกำหนดค่าที่ซับซ้อน',
  '邮件配置': 'การกำหนดค่าจดหมาย',
  '看板状态': 'สถานะกระดานข่าว',
  '请输入系统名称': 'โปรดป้อนชื่อระบบ',
  '请输入域名': 'กรุณาใส่ชื่อโดเมน',
  'logo裁剪': 'ครอบตัดโลโก้',
  '预览': 'ตัวอย่าง',
  '选择图片': 'เลือกรูปภาพ',
  '放大': 'ขยาย',
  '缩小': 'ลดขนาด',
  '左旋转': 'หมุนซ้าย',
  '右旋转': 'หมุนขวา',
  '上传图片': 'อัปโหลดรูปภาพ',
  '推荐使用：透明白色logo；尺寸比例：宽200~240px，高57px。': 'แนะนำ: โลโก้สีขาวใส; ขนาดอัตราส่วน: W 200 ~ 240px, H 57px',
  '操作日志': 'บันทึกการทำงาน',
  'ID': 'ID',
  'TraceId': 'TraceId',
  '操作人': 'ผู้ดำเนินการ',
  '执行方法': 'วิธีการดำเนินการ',
  'IP地址': 'ที่อยู่ IP',
  '地区': 'ภูมิภาค',
  '耗时': 'ใช้เวลา',
  '配置中心': 'ศูนย์กำหนดค่า',
  '客户资料': 'ประวัติลูกค้า',
  '请输入客户名称': 'กรุณาใส่ชื่อลูกค้า',
  '锡膏虚拟客户': 'ลูกค้าเสมือนวางประสาน',
  '胶水虚拟客户': 'กาวลูกค้าเสมือน',
  '自购料': 'ซื้อวัสดุด้วยตนเอง',
  '添加客户': 'เพิ่มลูกค้า',
  '编辑客户': 'แก้ไขลูกค้า',
  '导入Excel': 'นำเข้า Excel',
  '客户编号': 'หมายเลขลูกค้า',
  '客户名称': 'ชื่อลูกค้า',
  '客户简称': 'ชื่อย่อลูกค้า',
  '地址': 'ที่อยู่',
  '是': 'ใช่',
  '否': 'ไม่',
  '客户地址': 'ที่อยู่ลูกค้า',
  '详细地址': 'ที่อยู่โดยละเอียด',
  '联系方式': 'ติดต่อ',
  '请选择客户地址': 'กรุณาเลือกที่อยู่ของลูกค้า',
  '请输入客户编号': 'กรุณากรอกหมายเลขลูกค้า',
  '请输入客户简称': 'โปรดป้อนชื่อย่อของลูกค้า',
  '请输入详细地址': 'กรุณาระบุที่อยู่โดยละเอียด',
  '请输入联系方式': 'กรุณากรอกรายละเอียดการติดต่อ',
  '上传的文件大小不能超过100MB！': 'ขนาดไฟล์ที่อัปโหลดต้องไม่เกิน 100MB!',
  '编辑租户': 'แก้ไขผู้เช่า',
  '租户状态': 'สถานะผู้เช่า',
  '暂无数据': 'ไม่มีข้อมูลชั่วคราว',
  '请输入图标名称': 'โปรดป้อนชื่อไอคอน',
  '添加选项': 'เพิ่มตัวเลือก',
  '关键词': 'คำสำคัญ',
  '租户企业全称': 'ชื่อเต็มของธุรกิจผู้เช่า',
  '系统版本信息': 'ข้อมูลเวอร์ชั่นระบบ',
  '许可证书类型': 'ประเภทใบอนุญาต',
  '添加许可证书': 'เพิ่มใบอนุญาต',
  '导入服务器信息': 'นำเข้าข้อมูลเซิร์ฟเวอร์',
  '许可证书序列号': 'ใบอนุญาต หมายเลขซีเรียล',
  '用户数': 'จำนวนผู้ใช้',
  '设备数': 'จำนวนอุปกรณ์',
  '有效期': 'วันหมดอายุ',
  'mac地址': 'ที่อยู่ Mac',
  '服务器唯一码': 'รหัสเฉพาะของเซิร์ฟเวอร์',
  '供应商全称': 'ชื่อเต็มของผู้ให้บริการ',
  '下载': 'ดาวน์โหลด',
  '下载成功': 'ดาวน์โหลดสำเร็จ',
  '导出成功': 'ส่งออกสำเร็จ',
  '租户': 'ผู้เช่า',
  '选择租户': 'เลือกผู้เช่า',
  '请选择租户': 'กรุณาเลือกผู้เช่า',
  '请输入供应商全称': 'กรุณาใส่ชื่อเต็มของผู้ขาย',
  '请输入租户企业全称': 'กรุณาใส่ชื่อเต็มของธุรกิจผู้เช่า',
  '请输入系统版本信息': 'กรุณากรอกข้อมูลเวอร์ชั่นของระบบ',
  '请选择许可证书类型': 'กรุณาเลือกประเภทใบอนุญาต',
  '请输入mac地址': 'โปรดป้อนที่อยู่ Mac',
  '请输入许可证书序列号': 'โปรดป้อนหมายเลขซีเรียลของใบอนุญาต',
  '请输入用户数': 'กรุณาใส่จำนวนผู้ใช้',
  '请输入设备数': 'กรุณาใส่จำนวนอุปกรณ์',
  '请选择有效期': 'กรุณาเลือกวันหมดอายุ',
  '租户编号': 'หมายเลขผู้เช่า',
  '服务器配置': 'การกำหนดค่าเซิร์ฟเวอร์',
  '添加服务器': 'เพิ่มเซิร์ฟเวอร์',
  '编辑服务器': 'แก้ไขเซิร์ฟเวอร์',
  'cpu内核信息': 'ข้อมูลเคอร์เนล CPU',
  '逻辑cpu核数': 'จำนวนแกน CPU ตรรกะ',
  '物理cpu核数': 'จำนวนแกนซีพียูทางกายภาพ',
  '系统内存信息': 'ข้อมูลหน่วยความจำระบบ',
  '处理器id': 'รหัสประมวลผล',
  '操作系统厂商': 'ผู้ผลิตระบบปฏิบัติการ',
  '型号': 'รุ่น',
  '未知': 'ไม่ทราบ',
  '请输入cpu内核信息': 'โปรดป้อนข้อมูลเคอร์เนล CPU',
  '请输入逻辑cpu核数': 'โปรดป้อนจำนวนแกนซีพียูตรรกะ',
  '请输入物理cpu核数': 'โปรดป้อนจำนวนแกนซีพียูทางกายภาพ',
  '请输入系统内存信息': 'โปรดป้อนข้อมูลหน่วยความจำระบบ',
  '请输入处理器id': 'โปรดป้อนรหัสประมวลผล',
  '请输入操作系统厂商': 'โปรดป้อนผู้ผลิตระบบปฏิบัติการ',
  '请输入型号': 'โปรดป้อนหมายเลขรุ่น',
  '提示：': 'เคล็ดลับ:',
  '一级目录组件地址：': 'ที่อยู่ส่วนประกอบของแคตตาล็อกระดับแรก:',
  '子级目录组件地址：': 'ที่อยู่ส่วนประกอบของแคตตาล็อกระดับย่อย:',
  '记住账号密码': 'จำรหัสผ่านของบัญชี',
  '租户全称': 'ชื่อเต็มของผู้เช่า',
  '请输入租户全称': 'กรุณาใส่ชื่อเต็มของผู้เช่า',
}