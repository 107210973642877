import md5 from 'js-md5'
import request from '@/utils/request'
/* 登录 */


// 登录
export function login(data) {
  return request({
    url: '/imes-uaa-license/oauth/token',
    method: 'post',
    headers: {
      Authorization: 'Basic bWF0ZTptYXRlX3NlY3JldA==',
    },
    params: {
      name: data.name,
      username: data.username,
      password: md5(data.password),
      grant_type: 'password',
      scope: 'all',
      parameters: 'fsf',
    },
    //getAllData: true, // 获取全部返回的数据
  })
}

// 退出登录
export function logout() {
  return request({
    url: '/imes-uaa-license/auth/logout',
    method: 'post',
  })
}

// 菜单树
export function tree() {
  return request({
    url: 'imes-system-license/menu/tree',
    method: 'get',
  })
}

// 权限列表
export function auth() {
  return request({
    url: 'imes-uaa-license/auth/get/user',
    method: 'get',
  })
}

// 获取公司名称及logo
export function oneImgAndName() {
  return request({
    url: 'imes-system-license/tenant/getOneImgAndName',
    method: 'get',
  })
}