import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import store from '@/store/index'
import login from './modules/login'
//import staticRoute from './modules/staticRoute' /* 仅用于开发者本地测试的静态路由 */
import { createRouter, createWebHashHistory } from 'vue-router'
import { ElMessage } from 'element-plus'
import { translate } from '@/utils/i18n'
import { tree } from '@/api/login/index'

let getRouteFlag = true // 正在获取菜单
let trees = []

// 创建路由
const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes: [
    ...login,
  ]
})

// 路由守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : document.title
  NProgress.start() // 进度条
  const currTime = Date.now()
  const accessToken = JSON.parse(localStorage.getItem('accessToken')) || ''

  // 判断登录状态是否过期（登录状态有效期为7天，时间戳604800000减去1分钟的误差）
  if (accessToken && accessToken.value && (currTime - accessToken.time) < 604740000) {
    if (to.path == '/') {
      // 禁止返回登录页
      if (getRouteFlag) {
        getRoute().then(() => {
          router.push({ path: getDirectoryFirstChild(trees[0]) })
        }).catch(() => {
          store.dispatch('logOut')
          ElMessage.warning(translate('暂无权限，请联系后台管理员'))
          next({ path: '/' })
        })
      } else {
        next({ path: from.path, query: from.query })
      }
    } else {
      if (getRouteFlag) {
        getRoute().then(() => {
          router.push({ path: to.path, query: to.query })
        }).catch(() => {
          store.dispatch('logOut')
          ElMessage.warning(translate('暂无权限，请联系后台管理员'))
          next({ path: '/' })
        })
      } else {
        next()
      }
    }
  } else {
    if (to.path != '/') {
      store.dispatch('logOut')
      ElMessage.warning(translate('登录状态已过期，请重新登录!'))
      next({ path: '/' })
    } else {
      next()
    }
  }
})

// 路由守卫
router.afterEach(() => {
  NProgress.done() // 进度条
})

// 获取动态路由
export function getRoute() {
  return new Promise((resolve,reject) => {
    if (!localStorage.getItem('userInfo')) return reject()
    tree().then(res => {
      trees = handleRouteData(res.data)
      if (!trees.length) reject()
      store.dispatch('setTree', trees)
      trees.forEach(item => {
        router.addRoute(item)
      })
      getRouteFlag = false
      resolve()
    }).catch(() => {})
  })
}

// 处理路由数据
const handleRouteData = function(data){
  data.forEach((item, index, array) => {
    // 如果类型为0目录：
    // redirect为空，默认设置为第一个children的path
    // redirect指向的地址children中没有，则改为第一个children的path
    if (item.type == 0) {
      if (!item.redirect) {
        if (item.children?.length) {
          item.redirect = item.children[0]?.path || ''
        }
      } else {
        if (item.children?.length) {
          const filter = item.children.filter(ic => { return ic.path == item.redirect})
          if (filter.length === 0) {
            item.redirect = item.children[0].path
          }
        }
      }
    } 
    // 0目录、1菜单才有component
    if (item.type == 0 || item.type == 1) {
      const component = item.component.replace('@/', '')
      array[index].component = () => import(`@/${component}`)
    }
    // 递归渲染子节点
    if (item.children?.length) {
      array[index].children = handleRouteData(array[index].children)
    }
  })
  return data
}

// 获取目录的第一个子路由（类型为菜单1）
export function getDirectoryFirstChild(data) {
  if (data.type == 0) {
    if (data.children?.length) {
      return getDirectoryFirstChild(data.children[0])
    } else {
      return data.path
    }
  } else if (data.type == 1) {
    return data.path
  }
}

export default router