/* i18n语言包（英语） */

export default {
  'sys.operator.success': 'sys.operator.success',
  '业务异常': 'Business Exception',
  '网络请求失败，请稍候再试': 'Network request failed, please try again later',
  '您好！': 'Hello!',
  '欢迎来到license！': 'Welcome to license!',
  '请输入公司关键词': 'Please enter company keywords',
  '公司名不能为空': 'Company name cannot be empty',
  '请输入用户名': 'Please enter a name',
  '用户名不能为空': 'Name cannot be empty',
  '请输入密码': 'Please enter the password',
  '密码不能为空': 'Password cannot be empty',
  '登录': 'log on',
  '退出登录': 'Log out of login',
  '登录成功': 'Login succeeded',
  '暂无权限，请联系后台管理员': 'No permission currently, please contact the backend administrator',
  '向右滑动完成拼图': 'Slide right to complete the puzzle',
  '刷新': 'Refresh',
  '抱歉！': 'Sorry!',
  '当前页面不存在...': 'The current page does not exist...',
  '请检查您输入的网址是否正确，或点击下面的按钮返回首页。': 'Please check if the website address you entered is correct, or click the button below to return to the homepage.',
  '登录状态已过期，请重新登录!': 'Login status has expired, please log in again!',
  '返回首页': 'Back homePage',
  '关闭其他': 'Close Other', 
  '关闭左侧': 'Close Left side', 
  '关闭右侧': 'Close Right Side', 
  '关闭全部': 'Close All', 
  '搜索': 'Search',
  '清空': 'Empty',
  '添加': 'Add',
  '删除': 'Delete',
  '批量删除': 'Batch Delete',
  '启用': 'Enable',
  '禁用': 'Disable',
  '编辑': 'Edit',
  '修改': 'Modify',
  '审核': 'Approve',
  '反审核': 'De-approval',
  '条码': 'bar code',
  '详情': 'Details',
  '导出Excel': 'Export to Excel',
  '边框': 'Border',
  '大': 'Large',
  '中': 'Medium',
  '小': 'Small',
  '许可证书管理': 'License Management', 
  '许可证书': 'License', 
  '请输入许可证名称': 'Please enter the license name',
  '系统管理': 'System manage', 
  '菜单管理': 'Menu manage', 
  '开始日期': 'Start date',
  '至': 'to',
  '结束日期': 'End date',
  'ID或菜单名称': 'ID or menu name',
  '请输入权限值': 'Please enter permission values',
  '菜单名称': 'Menu name',
  '图标': 'Icon',
  '选择图标': 'Select icon',
  '组件路径': 'Component Path',
  '类型': 'Type',
  '排序': 'Sort',
  '目录': 'Directory',
  '菜单': 'Menu',
  '按钮': 'Button',
  '未选中任何行': 'No rows selected',
  '操作成功': 'Operation successful',
  '确认要启用选中的': 'Are you sure you want to enable the selected',
  '确认要禁用选中的': 'Are you sure you want to disable the selected',
  '确认要删除选中的': 'Confirm to delete the selected',
  '条数据？': 'pieces of data?',
  '取消': 'cancel',
  '确定': 'confirm',
  '添加菜单': 'Add menu',
  '编辑菜单': 'Edit menu',
  '上级菜单': 'Parent menu',
  '请选择上级菜单': 'Please select the parent menu',
  '菜单类型': 'Menu type',
  '菜单图标': 'Menu icon',
  '图标选择器': 'Icon selector',
  '显示排序': 'Display sorting',
  '路由地址': 'Routing address',
  '组件地址': 'Component Address',
  '路由重定向': 'Routing redirection',
  '权限标识': 'Permission identification',
  '菜单状态': 'Menu status',
  '是否隐藏': 'Hidden',
  '请输入菜单名称': 'Please enter a menu name',
  '请输入排序': 'Please enter sorting',
  '请输入路由地址': 'Please enter a routing address',
  '请输入组件地址': 'Please enter the component address',
  '请输入路由重定向': 'Please enter route redirection',
  '请输入权限标识': 'Please enter the permission identifier',
  '显示': 'Display',
  '隐藏': 'Hidden',
  '添加子项': 'Add Child',
  '添加翻译': 'Add translate',
  '确认删除名称为': 'Are you sure you want to delete the data item named',
  '的数据项?': '?',
  '语言类型': 'Language Type',
  '翻译内容': 'Translation content',
  '请先选中某一数据行进行操作': 'Please select a certain menu line for operation first',
  '请选择语言类型': 'Please select a language type',
  '请输入翻译内容': 'Please enter the translation content',
  '确认要删除当前项吗？': 'Are you sure you want to delete the current item?',
  '确认要删除选中项吗？': 'Are you sure you want to delete the selected item?',
  '操作': 'Operate',
  '角色管理': 'Role manage',
  '角色编号': 'Role Number',
  '角色名称': 'Role Name',
  '角色编码': 'Role Code',
  '角色描述': 'Role Description',
  '请输入角色编码': 'Please enter role code',
  '请输入角色名称': 'Please enter a role name',
  '请输入描述': 'Please enter a description',
  '描述': 'Description',
  '添加角色': 'Add role',
  '编辑角色': 'Edit role',
  '权限设置': 'Permission settings',
  '保存权限设置': 'Save permission settings',
  '用户管理': 'User management',
  '请选择角色': 'Please select a role',
  '请选择部门': 'Please select a department',
  '用户编号': 'User ID',
  '用户头像': 'User profile',
  '账号': 'Account',
  '昵称': 'Nickname',
  '角色': 'Role',
  '部门': 'Department',
  '手机号码': 'Mobile phone number',
  '邮箱': 'Mailbox',
  '状态': 'State',
  '账户': 'Account',
  '姓名': 'Name',
  '密码': 'Password',
  '确认密码': 'Confirm Password',
  '性别': 'Gender',
  '男': 'Male',
  '女': 'Female',
  '职务': 'Post',
  '员工编号': 'Employee ID',
  '出生日期': 'Date of birth',
  '用户状态': 'User Status',
  '请输入账户': 'Please enter an account',
  '请输入姓名': 'Please enter your name',
  '请输入确认密码': 'Please enter a confirmation password',
  '请输入员工编号': 'Please enter employee number',
  '添加用户': 'Add user',
  '编辑用户': 'Edit user',
  '请输入手机号码': 'Please enter your phone number',
  '请输入邮箱地址': 'Please enter an email address',
  '请选择职务': 'Please select a position',
  '请选择出生日期': 'Please select a date of birth',
  '两次输入的密码不一致': 'Inconsistent passwords entered twice',
  '长度在 6 到 20 个字符': 'Length between 6 and 20 characters',
  '重置密码': 'Reset password',
  '部门管理': 'Department Management',
  '添加部门': 'Add Department',
  '编辑部门': 'Edit Department',
  '部门名称': 'Department Name',
  '部门编号': 'Department Number',
  '部门负责人': 'Department Head',
  '上级部门': 'Superior department',
  '负责人': 'Responsible person',
  '请选择上级部门': 'Please select a superior department',
  '请输入部门名称': 'Please enter the department name',
  '职务管理': 'Duties Management',
  '部门职务管理': 'Department duties Management',
  '请选择部门负责人': 'Please select department head',
  '选择负责人': 'Select responsible person',
  '返回': '返回',
  '职务详情列表': 'Duties Details List',
  '职务名称': 'Duties Name',
  '创建人': 'Created by',
  '创建人ID': 'Creator ID',
  '创建时间': 'Creation time',
  '更新人': 'Updated by',
  '更新人ID': 'Updated by ID',
  '更新时间': 'Update time',
  '请输入职务名称': 'Please enter duties name',
  '添加职务': 'Add duties',
  '编辑职务': 'Edit duties',
  '字典管理': 'Dictionary Management',
  '字典编号或名称': 'dictionary number or name',
  '添加字典': 'Add Dictionary',
  '编辑字典': 'Edit Dictionary',
  '字典编号': 'Dictionary number',
  '字典名称': 'Dictionary name',
  '选项名称': 'Option Name',
  '选项值': 'Option Value',
  '请输入字典编号': 'Please enter dictionary number',
  '请输入字典名称': 'Please enter a dictionary name',
  '请输入选项值': 'Please enter option value',
  '请输入选项名称': 'Please enter option name',
  '备注': 'Remarks',
  '请输入备注': 'Please enter comments',
  '添加字典选项': 'Add Dictionary Options',
  '编辑字典选项': 'Edit Dictionary Options',
  '字典列表': 'Dictionary List',
  '字典选项': 'Dictionary Options',
  '翻译列表': 'Translation List',
  '租户管理': 'Tenant Management',
  '添加租户': 'Add Tenant',
  '配置': 'Config',
  '序号': 'Serial Number',
  '租户logo': 'Tenant logo',
  '租户名称': 'Tenant Name',
  '租户ID': 'Tenant ID',
  '联系人': 'Contact Person',
  '联系电话': 'Contact phone number',
  '开始时间': 'Start Time',
  '结束时间': 'End time',
  '请输入租户名称': 'Please enter the tenant name',
  '请输入租户编码': 'Please enter tenant code',
  '请输入联系人': 'Please enter a contact person',
  '请输入联系电话': 'Please enter the contact phone number',
  '请选择开始时间': 'Please select a start time',
  '请选择结束时间': 'Please select the end time',
  '租户编码': 'Tenant Code',
  '备注：租户默认管理用户：admin，密码为联系电话。': 'Note: Tenant default management user: admin, password is contact phone number.',
  '公司logo': 'Company logo',
  '系统名称': 'System Name',
  '域名': 'Domain name',
  '复杂配置': 'Complex configuration',
  '邮件配置': 'Mail Configuration',
  '看板状态': 'Bulletin board status',
  '请输入系统名称': 'Please enter the system name',
  '请输入域名': 'Please enter the domain name',
  'logo裁剪': 'Logo cropping',
  '预览': 'preview',
  '选择图片': 'Select Picture',
  '放大': 'enlarge',
  '缩小': 'narrow',
  '左旋转': 'Left rotation',
  '右旋转': 'Right rotation',
  '上传图片': 'Upload Picture',
  '推荐使用：透明白色logo；尺寸比例：宽200~240px，高57px。': 'Recommended use: Transparent white logo; Size ratio: width 200-240px, height 57px.',
  '操作日志': 'Operation log',
  'ID': 'ID',
  'TraceId': 'TraceId',
  '操作人': 'Operator',
  '执行方法': 'Execution method',
  'IP地址': 'IP address',
  '地区': 'Region',
  '耗时': 'Time consuming',
  '配置中心': 'Configuration Center',
  '客户资料': 'Customer Information',
  '请输入客户名称': 'Please enter the customer name',
  '锡膏虚拟客户': 'Solder Paste Virtual Customer',
  '胶水虚拟客户': 'Glue virtual customer',
  '自购料': 'Self purchased materials',
  '添加客户': 'Add Customer',
  '编辑客户': 'Edit Customer',
  '导入Excel': 'Import Excel',
  '客户编号': 'Customer number',
  '客户名称': 'Customer Name',
  '客户简称': 'Customer Abbreviation',
  '地址': 'Address',
  '是': 'Yes',
  '否': 'No',
  '客户地址': 'Customer Address',
  '详细地址': 'Detailed address',
  '联系方式': 'Contact information',
  '请选择客户地址': 'Please select a customer address',
  '请输入客户编号': 'Please enter the customer number',
  '请输入客户简称': "Please enter the customer's short name",
  '请输入详细地址': 'Please enter detailed address',
  '请输入联系方式': 'Please enter contact information',
  '上传的文件大小不能超过100MB！': 'The size of the uploaded file cannot exceed 100MB!',
  '编辑租户': 'Edit Tenant',
  '租户状态': 'Tenant Status',
  '暂无数据': 'No Data',
  '请输入图标名称': 'Please enter an icon name',
  '添加选项': 'Add Options',
  '关键词': 'Keyword',
  '租户企业全称': "Full name of tenant enterprise",
  '系统版本信息': 'System version information',
  '许可证书类型': 'License Type',
  '添加许可证书': 'Add License Certificate',
  '导入服务器信息': 'Import Server Information',
  '许可证书序列号': 'License Certificate Serial Number',
  '用户数': 'Number of users',
  '设备数': 'Number of devices',
  '有效期': 'Validity period',
  'mac地址': 'MAC address',
  '服务器唯一码': 'Server Unique Code',
  '供应商全称': 'Full name of supplier',
  '下载': 'Download',
  '下载成功': 'Download successful',
  '导出成功': 'Export successful',
  '租户': 'Tenant',
  '选择租户': 'Select Tenant',
  '请选择租户': 'Please select Tenant',
  '请输入供应商全称': 'Please enter the full name of the supplier',
  '请输入租户企业全称': "Please enter the full name of the tenant's enterprise",
  '请输入系统版本信息': 'Please enter system version information',
  '请选择许可证书类型': 'Please select the type of license certificate',
  '请输入mac地址': 'Please enter the MAC address',
  '请输入许可证书序列号': 'Please enter the license certificate serial number',
  '请输入用户数': 'Please enter the number of users',
  '请输入设备数': 'Please enter the number of devices',
  '请选择有效期': 'Please select an expiration date',
  '租户编号': 'Tenant ID',
  '服务器配置': 'Server Configuration',
  '添加服务器': 'Add Server',
  '编辑服务器': 'Edit Server',
  'cpu内核信息': 'CPU kernel information',
  '逻辑cpu核数': 'Number of logical CPU cores',
  '物理cpu核数': 'Number of physical CPU cores',
  '系统内存信息': 'System Memory Information',
  '处理器id': 'Processor ID',
  '操作系统厂商': 'Operating system manufacturer',
  '型号': 'Model',
  '未知': 'Unknown',
  '请输入cpu内核信息': 'Please enter CPU kernel information',
  '请输入逻辑cpu核数': 'Please enter the number of logical CPU cores',
  '请输入物理cpu核数': 'Please enter the number of physical CPU cores',
  '请输入系统内存信息': 'Please enter system memory information',
  '请输入处理器id': 'Please enter the processor ID',
  '请输入操作系统厂商': 'Please enter the operating system manufacturer',
  '请输入型号': 'Please enter the model number',
  '提示：': 'Reminder:',
  '一级目录组件地址：': 'First level directory component address:',
  '子级目录组件地址：': 'Sub directory component address:',
  '记住账号密码': 'Remember account password',
  '租户全称': 'Full name of tenant',
  '请输入租户全称': 'Please enter the full name of the tenant',
}